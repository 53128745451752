.jitsu-message {
  @apply flex justify-center pt-2;
  position: fixed;
  width: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
}

.jitsu-message-notice {
  margin-top: 2px;
}

.jitsu-message-notice-content {
  @apply bg-bgComponent px-4 py-3 rounded-lg inline-block;
  box-sizing: border-box;
  display: inline-block;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}

.jitsu-message-notice-content > .jitsu-message-custom-content > .anticon {
  display: none;
}

.jitsu-message-notice.jitsu-move-up-leave.jitsu-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}

.jitsu-message-notice .close-btn > span {
  font-size: 18px;
  vertical-align: -0.215em;
}

.app-update-notice {
  .jitsu-message-notice-content {
    font-size: 24px;
  }
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    margin-top: 2px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-top: -10px;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    margin-top: 2px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-top: -10px;
    opacity: 0;
  }
}

@bgPrimary: rgb(17, 24, 39);@bgSecondary: rgb(31, 41, 55);@bgComponent: rgb(26, 35, 50);@bgTableHeader: @bgComponent;@splitBorder: #394e5a;@primary: rgb(91, 20, 250);@link: rgb(135, 138, 252);@success: rgb(11, 173, 81);@successHover: #0aa14b;@successActive: #2cc56f;@warning: #ffc021;@error: #e53935;@heading: #c1c9d2;@text: #e5e7eb;@textPale: #ffffff;@secondaryText: #7996a9;@disabled: #415969;@secondaryBorder: #415969;@secondaryBorderHover: #878afc;@secondaryBorderFocus: #5b14fa;@background-color-light: @bgComponent;@primary-background: @bgPrimary;@secondary-background: @bgSecondary;@component-background: @bgComponent;@popover-background: @bgComponent;@link-background: @link;@table-header-bg: @bgTableHeader;@table-row-hover-bg: @bgPrimary;@table-header-sort-active-bg: @bgTableHeader;@table-header-sort-bg: @bgTableHeader;@border-color-split: @splitBorder;@primary-color: @primary;@primary-5: @link;@link-color: @link;@success-color: @success;@warning-color: @warning;@error-color: @error;@heading-color: @heading;@text-color: @text;@text-color-secondary: @secondaryText;@disabled-color: @disabled;@border-color-base: @secondaryBorder;@border-hover-color-base: @secondaryBorderHover;@border-focus-color-base: @secondaryBorderFocus;@divider-color: @secondaryBorder;@alert-success-bg-color: @success-color;@alert-warning-bg-color: @warning-color;@alert-error-bg-color: @error-color;@alert-text-color: @bgPrimary;@alert-message-color: @bgPrimary;@border-radius-base: 6px;@font-size-base: 14px;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);@large-border-radius: 12px;@small-border-radius: 6px;@font-main: "system-ui", sans-serif;@font-monospace: "Source Code Pro", monospace;@font-heading: "Nunito Sans", sans-serif;@default-transition-duration: 200ms;@default-transition-delay: 0ms;@default-transition-animation: linear;@default-transiton: @default-transition-duration @default-transition-animation @default-transition-delay;